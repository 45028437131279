import { Brand } from "../configurations";
import {
  EagleMigrationStateRedirect,
  EagleRegisterLink,
  EagleAndroidAppLink,
  EagleIOSAppLink,
  DialogType,
  Channel,
  Topic
} from "../constants";
import { MessageBrokerUtils } from "./messageBrokerUtils";
import { PageContextUtils } from "./pageContextUtils";

export class MigrationUtils {
  static handleOpenRegistration = isNative => {
    if (isNative) {
      MigrationUtils.openNewPlatformPopup();
      return;
    }

    window.location.href = EagleRegisterLink;
    return;
  };

  static openNativeRegistrationLink = isIOS => {
    var link = EagleAndroidAppLink;
    if (isIOS) {
      link = EagleIOSAppLink;
    }

    window.location.href = link;
    return;
  };

  static openNewPlatformPopup = () => {
    MessageBrokerUtils.publish({
      channel: Channel.UI,
      topic: Topic.Dialog,
      headers: {
        publisher: "UAA"
      },
      data: {
        dialogType: DialogType.NewPlatform
      }
    });
  };

  static isSportRoMigrationOngoing = () => {
    var { configuration } = PageContextUtils.getMigrationData();

    if (configuration.stateId >= EagleMigrationStateRedirect) return true;
    return false;
  };
}
