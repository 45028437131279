import { Channel, Topic } from "../constants";
import { MessageBrokerUtils } from "../utils";
import { useCallback } from "react";
import { performAction } from "../utils/actionUtils";
import { UserContextSelectors } from "../redux/selectors";
import { useSelector } from "react-redux";
import { MigrationUtils } from "../utils/migrationUtils";
import { useFeature } from "./useFeature";
import { AppFeatures } from "../constants/featureFlags";

export const useAccountCommunicationHandlers = () => {
  const openMyAccount = useCallback(data => {
    MessageBrokerUtils.publish({
      channel: Channel.PLAYER,
      topic: Topic.CTA_MyAccount_Open,
      headers: { publisher: "UAA" },
      data: data
    });
  }, []);

  const openLogin = useCallback(() => {
    MessageBrokerUtils.publish({
      channel: Channel.PLAYER,
      topic: Topic.loginCompletion,
      headers: { publisher: "UAA" }
    });
  }, []);

  const useOpenRegister = () => {
    const brandId = useSelector(UserContextSelectors.brandId);
    const isNative = useSelector(UserContextSelectors.isNative);
    const isEagleMigrationStarted = useFeature(AppFeatures.EAGLE_MIGRATION);

    return useCallback(() => {
      // For 888Ro brand, registration is handled externaly via Eagle
      if (
        isEagleMigrationStarted &&
        MigrationUtils.isSportRoMigrationOngoing()
      ) {
        MigrationUtils.handleOpenRegistration(isNative);
        return;
      }

      performAction("register");
    }, [brandId, isNative, isEagleMigrationStarted]);
  };

  const openChallengesOrPromotions = useCallback(() => {
    // the logic to determine promotions or challenges is implemented in the action itself
    performAction("openPromotions");
  }, []);

  return {
    openMyAccount,
    openLogin,
    useOpenRegister,
    openChallengesOrPromotions
  };
};
