import { useEffect } from "react";

export const useUserarea = () => {
  useEffect(() => {
    window.libraryManager?.UserareaLibrary?.loaded();
    document
      .querySelector("#uc-wrapper-container")
      .classList.remove("visible--el");

    setTimeout(() => {
      document
        .querySelector(".uc-content-area")
        .classList.remove("visible--el");
    }, 100);
  }, []);
};
