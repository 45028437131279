import { Channel, Topic } from "../constants";
import { PageContextUtils } from ".";
import { MessageBrokerUtils } from ".";
import { v4 as uuidv4 } from "uuid";
import merge from "lodash/merge";

const getDataByAction = (action, overrides = {}) => {
  const { rms } = PageContextUtils.getUserData();
  const { clientVersion } = PageContextUtils.getDeviceData();
  const defaultValues = {
    correlationID: uuidv4(),
    launchInfo: {
      businessCorrelationID: uuidv4(),
      sequentialCorrelationID: 1,
      trigger: "userSelection",
      sourceAppID: "UO_UNIFIEDCLIENT",
      sourceAppVersion: "0.0.0",
      clientVersion
    },
    actionData: {}
  };
  const actions = {
    openChat: {
      ...defaultValues,
      actionID: "openChat",
      launchInfo: {
        ...defaultValues.launchInfo,
        channel: {
          area: "button",
          source: "Unified Client",
          element: "Default"
        },
        appSpecificParameters: {
          target: "ChatTab",
          featureType: 1,
          playerValue: rms,
          isAuthenticated: true
        }
      }
    },
    openHelp: {
      ...defaultValues,
      actionID: "openHelp",
      launchInfo: {
        ...defaultValues.launchInfo,
        channel: {
          area: "button",
          source: "Unified Client",
          element: "Default"
        },
        appSpecificParameters: {
          target: "HelpTab",
          featureType: 1,
          playerValue: rms,
          isAuthenticated: true
        }
      }
    },
    openCasino: {
      ...defaultValues,
      actionID: "openCasino",
      launchInfo: {
        ...defaultValues.launchInfo,
        channel: {
          area: "button",
          source: "Unified Client",
          element: "Default"
        }
      }
    },
    openCIS: {
      ...defaultValues,
      actionID: "openCIS",
      launchInfo: {
        ...defaultValues.launchInfo,
        channel: {
          area: "button",
          source: "Unified Client",
          element: "Default"
        }
      }
    },
    openControlCenterFull: {
      ...defaultValues,
      actionID: "openControlCenterFull",
      launchInfo: {
        ...defaultValues.launchInfo,
        appSpecificParameters: {
          clientPlatform: 1,
          source: undefined,
          deeplink: undefined
        },
        channel: {
          area: "UD",
          source: "Unified Client",
          element: "UD"
        }
      }
    },
    openControlCenterMini: {
      ...defaultValues,
      actionID: "openControlCenterMini",
      launchInfo: {
        ...defaultValues.launchInfo,
        appSpecificParameters: {
          clientPlatform: 1,
          source: undefined
        },
        channel: {
          area: "UD",
          source: "Unified Client",
          element: "UD"
        }
      }
    },
    register: {
      ...defaultValues,
      actionID: "register",
      launchInfo: {
        ...defaultValues.launchInfo,
        channel: {
          area: "button",
          source: "Unified Client",
          element: "UD"
        }
      }
    },
    openPIS: {
      ...defaultValues,
      actionID: "openPIS",
      launchInfo: {
        ...defaultValues.launchInfo,
        channel: {
          area: "button",
          source: "Unified Client",
          element: "Default"
        }
      }
    },
    openPromotions: {
      ...defaultValues,
      actionID: "openPromotions",
      launchInfo: {
        ...defaultValues.launchInfo,
        channel: {
          area: "button",
          source: "Unified Client",
          element: "Default"
        }
      }
    }
  };

  return actions[action] ? merge(actions[action], overrides) : null;
};

export const performAction = (actionId, overrides) => {
  const data = getDataByAction(actionId, overrides);
  MessageBrokerUtils.publish({
    channel: Channel.NAVIGATION,
    topic: Topic.performAction,
    headers: { publisher: "unifiedclient" },
    data
  });
};
