const AppFeatures = {
  SAFEZONE_GENERIC: "ABTestingSafeZoneToggle",
  AIRSHIP: "AIRSHIP",
  APP_CATALOG: "APP_CATALOG",
  APP_UPGRADE_REQUIRED: "APP_UPGRADE_REQUIRED",
  CASINO_CROSS_SELL: "CASINO_CROSS_SELL",
  CASINO_DAILY_WISH: "CASINO_DAILY_WISH",
  CASINO_IN_SPORT: "CASINO_IN_SPORT",
  CHAT_LINK_IS_FOR_VIP_ONLY: "CHAT_LINK_IS_FOR_VIP_ONLY",
  CONTROL_CENTER: "CONTROL_CENTER",
  CUSTOMER_RELATIONS: "CUSTOMER_RELATIONS",
  EAGLE_MIGRATION: "EAGLE_MIGRATION",
  FAVOURITE_SPORTS: "FAVOURITE_SPORTS",
  FULL_PWC: "IS_FULL_PWC",
  GERMANY_REGULATION: "GERMANY_REGULATION",
  HELP_WIDGET: "HELP_WIDGET",
  INIT_GEO_RETRY: "INIT_GEO_RETRY",
  IOS_NATIVE_CASINO: "IOS_NATIVE_CASINO",
  KAMBI_SEARCH: "KAMBI_SEARCH",
  LOYALTY_INDICATION: "LOYALTY_INDICATION",
  LUGAS_LOGOUT: "LUGAS_LOGOUT",
  MADE_FOR_YOU: "MADE_FOR_YOU",
  MINI_CASHIER: "MINI_CASHIER",
  NEW_BANNER: "NEW_BANNER",
  POKER_CROSS_SELL: "POKER_CROSS_SELL",
  POKER_CROSS_SELL_TESTING: "POKER_CROSS_SELL_TESTING",
  POKER_HISTORY: "POKER_HISTORY",
  PRESENT_BONUS_BALANACE: "PRESENT_BONUS_BALANACE",
  SETTINGS_DRAWER: "SETTINGS_DRAWER",
  SETTINGS_NOTIFICATIONS: "SETTINGS_NOTIFICATIONS",
  SMART_BANNER: "SMART_BANNER",
  SURVEY: "SURVEY",
  TEN_K_DRIVE: "TEN_K_DRIVE",
  THEME_MODE: "THEME_MODE",
  TIME_SPENT_REMINDER: "TIME_SPENT_REMINDER",
  TOUCH_ID: "TOUCH_ID",
  VERIFICATION_IV: "VERIFICATION_IV",
  ENABLE_GC_NDBS: "ENABLE_GC_NDBS",
  MOBILE_WEB_BOTTOM_NAVBAR: "MOBILE_WEB_BOTTOM_NAVBAR"
};

export { AppFeatures };
