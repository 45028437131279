import { PageContextUtils } from "../utils/pageContextUtils";
import { useFeature } from "../hooks/useFeature";
import { AppFeatures } from "../constants/featureFlags";

export class AppFeatureUtils {
  static findIfIsInFeature = appFeature => {
    const { features } = PageContextUtils.getFeaturesData();

    const isInFeature = features
      ?.filter(feature => feature.value.toLowerCase() === "on")
      .some(activeFeature => activeFeature.id === appFeature);

    return isInFeature;
  };

  static isBottomNavigationEnabled = () => {
    const isBottomNavigationEnabled = useFeature(
      AppFeatures.MOBILE_WEB_BOTTOM_NAVBAR
    );
    const { bottomBarNavigationComponents } = PageContextUtils.getVisualData();
    return isBottomNavigationEnabled && !!bottomBarNavigationComponents;
  };
}
